import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations, sql } from "drizzle-orm";
import {
  bigint,
  index,
  jsonb,
  pgEnum,
  pgTable,
  text,
  uuid,
} from "drizzle-orm/pg-core";

import timestamp from "../../types/timestamp";
import defaultSchema from "../base";
import { networkTable } from "../network";
import { projectsTable } from "../project/project";
import { campaignsTable } from "./campaign";

export enum CampaignContractErcType {
  ERC721 = "ERC721",
  ERC1155 = "ERC1155",
}

export const contractErcTypeEnum = pgEnum("campaign_contract_erc_type", [
  CampaignContractErcType.ERC721,
  CampaignContractErcType.ERC1155,
]);

export const campaignContractTable = pgTable(
  "campaign_contract",
  {
    ...defaultSchema,
    projectId: uuid("project_id")
      .notNull()
      .references(() => projectsTable.id, { onDelete: "cascade" }),
    campaignId: uuid("campaign_id")
      .notNull()
      .references(() => campaignsTable.id, { onDelete: "cascade" }),
    abi: jsonb("abi").$type<ABIItem[]>().default([]).notNull(),
    address: text("address").$type<`0x${string}`>().notNull(),
    data: jsonb("data").default({}).notNull(),
    description: text("description").default("").notNull(),
    endsAt: timestamp("ends_at")
      .default(sql`now()`)
      .notNull(),
    merkleProof: jsonb("merkle_proof")
      .$type<MerkleProof>()
      .default({ proofs: {}, root: "" })
      .notNull(),
    name: text("name").notNull(),
    // @deprecated use networkId instead
    _network: text("deprecated_network").default(""),
    placeholder: text("placeholder").default("").notNull(),
    rpc: text("rpc").default("").notNull(),
    shortDescription: text("short_description").default("").notNull(),
    startsAt: timestamp("starts_at")
      .default(sql`now()`)
      .notNull(),
    traits: jsonb("traits").$type<string[][]>().default([]).notNull(),
    limitPerSale: bigint("limit_per_sale", { mode: "number" }).notNull(),
    price: text("price").default("").notNull(),
    maxSupply: bigint("max_supply", { mode: "number" }).notNull(),
    totalSupply: bigint("total_supply", { mode: "number" }).notNull(),
    limitPerWallet: bigint("limit_per_wallet", { mode: "number" }).notNull(),
    ercType: contractErcTypeEnum("erc_type").notNull(),
    networkId: uuid("network_id").references(() => networkTable.id),
  },
  (table) => ({
    campaignContractIdIdx: index("campaignContractId_Idx").on(table.id),
    projectIdIdx: index("campaignContractProjectId_Idx").on(table.projectId),
  }),
);

export const contractsRelations = relations(
  campaignContractTable,
  ({ one }) => ({
    project: one(projectsTable, {
      fields: [campaignContractTable.projectId],
      references: [projectsTable.id],
    }),
    campaign: one(campaignsTable, {
      fields: [campaignContractTable.campaignId],
      references: [campaignsTable.id],
    }),
    network: one(networkTable, {
      fields: [campaignContractTable.networkId],
      references: [networkTable.id],
    }),
  }),
);

export type ContractRelations = Partial<{
  project: InferSelectModel<typeof projectsTable>;
  campaign: InferSelectModel<typeof campaignsTable>;
  network: InferSelectModel<typeof networkTable>;
}>;
export type Contract = InferSelectModel<typeof campaignContractTable> &
  ContractRelations;
export type NewContract = InferInsertModel<typeof campaignContractTable>;
export type ABIItem = {
  name: string;
  type: "function" | "event" | "constructor";
  inputs?: {
    name: string;
    type: string;
    indexed?: boolean;
  }[];
  outputs?: {
    name: string;
    type: string;
  }[];
  constant?: boolean;
  payable?: boolean;
  stateMutability: "pure" | "view" | "nonpayable" | "payable";
  signature?: string;
  visibility?: "public" | "external" | "internal" | "private";
};
export type Proof = {
  tier: string | number;
  proof: string[];
};
export type ProofMap = {
  [address: string]: Proof;
};

export type MerkleProof = {
  root: string;
  proofs: ProofMap;
};
