import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations, sql } from "drizzle-orm";
import {
  boolean,
  index,
  jsonb,
  pgTable,
  smallint,
  text,
  uuid,
} from "drizzle-orm/pg-core";

import timestamp from "../types/timestamp";
import defaultSchema from "./base";
import { campaignsTable } from "./campaign/campaign";
import type { MerkleProof } from "./campaign/campaignContract";

export const tiersTable = pgTable(
  "campaign_tier",
  {
    ...defaultSchema,
    campaignId: uuid("campaign_id")
      .notNull()
      .references(() => campaignsTable.id, { onDelete: "cascade" }),
    startsAt: timestamp("starts_at").default(sql`now()`),
    endsAt: timestamp("ends_at").default(sql`now()`),
    merkleProof: jsonb("merkle_proof")
      .$type<MerkleProof | null>()
      .default(null),
    name: text("name").notNull(),
    icon: text("icon").default("").notNull(),
    isPublic: boolean("is_public").default(false),
    order: smallint("order").notNull(),
  },
  (table) => ({
    idIdx: index("tierId_Idx").on(table.id),
    campaignIdIdx: index("tierCampaignId_Idx").on(table.campaignId),
  }),
);

export const tiersRelations = relations(tiersTable, ({ one }) => ({
  campaign: one(campaignsTable, {
    fields: [tiersTable.campaignId],
    references: [campaignsTable.id],
  }),
}));

export type TierRelations = Partial<{
  campaign: InferSelectModel<typeof campaignsTable>;
}>;
export type Tier = InferSelectModel<typeof tiersTable> & TierRelations;
export type NewTier = InferInsertModel<typeof tiersTable>;
