import { and, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../..";
import { db } from "../..";
import type {
  NewProjectLaunchpad,
  ProjectLaunchpad,
} from "../../schemas/project/projectLaunchpad";
import { projectLaunchpadTable } from "../../schemas/project/projectLaunchpad";
import { transactionOrDatabaseWrapper } from "../utils/transactions";

export async function getProjectLaunchpadByProjectIdDB(projectId: string) {
  return await db.query.projectLaunchpadTable.findFirst({
    where: and(
      eq(projectLaunchpadTable.projectId, projectId),
      isNull(projectLaunchpadTable.deletedAt),
    ),
  });
}

export async function createProjectLaunchpadDB(
  payload: NewProjectLaunchpad,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(projectLaunchpadTable)
      .values(payload)
      .returning();
    return result[0];
  }, transaction);
}

export async function upsertProjectLaunchpadDB(
  payload: ProjectLaunchpad,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(projectLaunchpadTable)
      .values({
        ...payload,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        deletedAt: null,
      })
      .onConflictDoUpdate({
        target: projectLaunchpadTable.id,
        set: {
          ...payload,
          updatedAt: new Date().toISOString(),
          deletedAt: null,
        },
        where: eq(projectLaunchpadTable.id, payload.id),
      })
      .returning();

    return result[0];
  }, transaction);
}
