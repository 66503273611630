import { and, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../";
import { db } from "../";
import type { NewSubscribe } from "../schemas/subscribe";
import { subscribeTable } from "../schemas/subscribe";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

export async function getSubscribeByIdDB(id: string) {
  const result = await db
    .select()
    .from(subscribeTable)
    .where(and(eq(subscribeTable.id, id), isNull(subscribeTable.deletedAt)))
    .limit(1);

  return result[0];
}

export async function getSubscribeByEmailDB(email: string) {
  const result = await db
    .select()
    .from(subscribeTable)
    .where(
      and(eq(subscribeTable.email, email), isNull(subscribeTable.deletedAt)),
    )
    .limit(1);

  return result[0];
}

export async function createSubscribeDB(
  newSubscribe: NewSubscribe,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(subscribeTable)
      .values(newSubscribe)
      .returning();

    return result[0];
  }, transaction);
}

export async function deleteSubscribeByIdDB(
  id: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .update(subscribeTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(and(eq(subscribeTable.id, id), isNull(subscribeTable.deletedAt)))
      .returning();
  }, transaction);
}

export async function deleteSubscribeByEmailDB(
  email: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .update(subscribeTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(
        and(eq(subscribeTable.email, email), isNull(subscribeTable.deletedAt)),
      )
      .returning();
  }, transaction);
}
