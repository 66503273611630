import { eq } from "drizzle-orm";

import type { Transaction } from "..";
import type { CampaignContent } from "../schemas";
import { campaignContentTable } from "../schemas";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

export async function upsertCampaignContentDB(
  payload: typeof campaignContentTable.$inferInsert,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(campaignContentTable)
      .values({
        ...payload,
        createdAt: new Date().toISOString(),
        updatedAt: null,
        deletedAt: null,
      })
      .onConflictDoUpdate({
        target: campaignContentTable.id,
        set: {
          ...payload,
          updatedAt: new Date().toISOString(),
          deletedAt: null,
        },
        where: eq(campaignContentTable.id, (payload as CampaignContent).id),
      })
      .returning();

    return result[0];
  }, transaction);
}
