import type { InferInsertModel } from "drizzle-orm";
import { type InferSelectModel, relations } from "drizzle-orm";
import { pgEnum, pgTable, text, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "../base";
import { campaignsTable } from "./campaign";

export enum CampaignContentSection {
  Details = "details",
  Utility = "utility",
  HowToPurchase = "how-to-purchase",
}

export const campaignContentSectionEnum = pgEnum("campaign_content_section", [
  CampaignContentSection.Details,
  CampaignContentSection.Utility,
  CampaignContentSection.HowToPurchase,
]);

export const campaignContentTable = pgTable("campaign_content", {
  ...defaultSchema,
  campaignId: uuid("campaign_id")
    .notNull()
    .references(() => campaignsTable.id, { onDelete: "cascade" }),
  section: campaignContentSectionEnum("section").notNull(),
  bodyMarkdown: text("body_markdown").notNull(),
  processedHtml: text("processed_html").notNull(),
});

export const campaignContentRelations = relations(
  campaignContentTable,
  ({ one }) => ({
    campaign: one(campaignsTable, {
      fields: [campaignContentTable.campaignId],
      references: [campaignsTable.id],
    }),
  }),
);

type CampaignContentTable = typeof campaignContentTable;
export type CampaignContent = InferSelectModel<CampaignContentTable>;
export type NewCampaignContent = InferInsertModel<CampaignContentTable>;
