import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { boolean, index, pgTable, text, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "../base";
import { campaignsTable } from "../campaign/campaign";
import { campaignContractTable } from "../campaign/campaignContract";
import { configurationsTable } from "../configurations";
import {
  type SocialNetworkEntity,
  socialNetworkTable,
} from "../socialNetworks";
import { usersTable } from "../users";
import { projectLaunchpadTable } from "./projectLaunchpad";

export const projectsTable = pgTable(
  "project",
  {
    ...defaultSchema,
    isActive: boolean("is_active").default(true).notNull(),
    banner: text("banner").default("").notNull(),
    userId: uuid("user_id")
      .notNull()
      .references(() => usersTable.id, { onDelete: "cascade" }),
    description: text("description").default("").notNull(),
    logo: text("logo").default("").notNull(),
    name: text("name").default("").notNull(),
    slug: text("slug").unique().default("").notNull(),
    domain: text("domain").notNull(),
    voteSnapshotSpace: text("vote_snapshot_space").$type<`${string}.eth`>(),
  },
  (table) => ({
    idIdx: index("projectId_Idx").on(table.id),
    userIdIdx: index("projectUserId_Idx").on(table.userId),
    nameIdx: index("projectName_Idx").on(table.name),
    slugIdx: index("projectSlug_Idx").on(table.slug),
  }),
);

export const projectsRelations = relations(projectsTable, ({ many, one }) => ({
  creator: one(usersTable, {
    fields: [projectsTable.userId],
    references: [usersTable.id],
  }),
  contracts: many(campaignContractTable),
  campaigns: many(campaignsTable),
  configuration: one(configurationsTable, {
    fields: [projectsTable.id],
    references: [configurationsTable.projectId],
  }),
  launchpad: one(projectLaunchpadTable, {
    fields: [projectsTable.id],
    references: [projectLaunchpadTable.projectId],
  }),
  socialNetwork: one(socialNetworkTable, {
    fields: [projectsTable.id],
    references: [socialNetworkTable.entityId],
  }),
}));

export type ProjectRelations = Partial<{
  creator: InferSelectModel<typeof usersTable>;
  socialNetwork: Omit<typeof socialNetworkTable.$inferSelect, "entityType"> & {
    entityType: SocialNetworkEntity.Project;
  };
  contracts: InferSelectModel<typeof campaignContractTable>[];
  campaigns: InferSelectModel<typeof campaignsTable>[];
  configuration: InferSelectModel<typeof configurationsTable>[];
  launchpad: InferSelectModel<typeof projectLaunchpadTable>;
}>;
export type ProjectInsertRelations = {
  socialNetwork: Omit<typeof socialNetworkTable.$inferInsert, "entityType"> & {
    entityType: SocialNetworkEntity.Project;
  };
  launchpad?: typeof projectLaunchpadTable.$inferInsert;
};
export type Project = InferSelectModel<typeof projectsTable>;
export type NewProject = InferInsertModel<typeof projectsTable>;
export type ProjectWithRelations = Project & ProjectRelations;
export type NewProjectWithRelations = NewProject & ProjectInsertRelations;
