import { PgTransaction } from "drizzle-orm/pg-core";

import type { Database, Transaction } from "../..";
import { db } from "../..";

type TransactionOrDatabase = Transaction | Database;

export function callRollbackIfExists(dbOrTransaction: TransactionOrDatabase) {
  if (dbOrTransaction instanceof PgTransaction) {
    return dbOrTransaction.rollback();
  }
  return dbOrTransaction;
}

export function transactionOrDatabase(
  transaction?: Transaction,
): TransactionOrDatabase {
  return transaction ?? db;
}

export async function transactionOrDatabaseWrapper<T>(
  callback: (trxOrDb: TransactionOrDatabase) => Promise<T>,
  _transaction?: Transaction,
): Promise<T> {
  const transaction = transactionOrDatabase(_transaction);
  try {
    return await callback(transaction);
  } catch (error) {
    console.error(error);
    callRollbackIfExists(transaction);
    throw error;
  }
}
