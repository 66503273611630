import { and, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../";
import { db } from "../";
import type { NewUser, User } from "../schemas/users";
import { usersTable } from "../schemas/users";
import parseRelations from "./utils/parseRelations";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

const DEFAULT_SELECT = {
  id: usersTable.id,
  username: usersTable.username,
  email: usersTable.email,
};

export async function getUserByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  try {
    if (withModels) {
      return await db.query.usersTable.findFirst({
        columns: {
          id: true,
          username: true,
          email: true,
        },
        where: and(eq(usersTable.id, id), isNull(usersTable.deletedAt)),
        with: parseRelations(withModels),
      });
    }

    const result = await db
      .select(DEFAULT_SELECT)
      .from(usersTable)
      .where(and(eq(usersTable.id, id), isNull(usersTable.deletedAt)))
      .limit(1);
    return result[0];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getUserByUsernameDB(username: string) {
  const result = await db
    .select(DEFAULT_SELECT)
    .from(usersTable)
    .where(and(eq(usersTable.username, username), isNull(usersTable.deletedAt)))
    .limit(1);
  return result[0];
}

export async function getFullUserDB(email: string, password: string) {
  const result = await db
    .select()
    .from(usersTable)
    .where(
      and(
        eq(usersTable.email, email),
        eq(usersTable.password, password),
        isNull(usersTable.deletedAt),
      ),
    )
    .limit(1);
  return result[0];
}

export async function createUserDB(
  newUser: NewUser,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb.insert(usersTable).values(newUser).returning();
    return result[0];
  }, transaction);
}

export async function updateUserDB(user: User, transaction?: Transaction) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(usersTable)
      .set(user)
      .where(eq(usersTable.id, user.id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteUserDB(id: string, transaction?: Transaction) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(usersTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(usersTable.id, id))
      .returning();
    return result[0];
  }, transaction);
}
