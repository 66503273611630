import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { index, jsonb, pgTable, text, uuid } from "drizzle-orm/pg-core";
import type { Config } from "tailwindcss";

import defaultSchema from "./base";
import { projectsTable } from "./project/project";
import { usersTable } from "./users";

export type EdgeConfigValue = {
  featureFlags: {
    [key: `enable${string}`]: boolean;
  };
  config: {
    [key: string]: any;
  };
};

const defaultEdgeFeatureFlags = {
  enableHomePageRedirect: false,
  enableCookie3: false,
  enableUkBan: false,
  enableBreadcrumbs: false,
};

const defaultEdgeConfig = {
  GOOGLE_ANALYTICS_ID: "",
  GOOGLE_TAG_MANAGER_ID: "",
  COOKIE3_SITE_ID: "",
  BLOCKPASS_API_KEY: "",
  BLOCKPASS_CLIENT_ID: "",
  landingPage: "",
  projectName: "",
};

export const configurationsTable = pgTable(
  "configuration",
  {
    ...defaultSchema,
    projectId: uuid("project_id")
      .notNull()
      .references(() => projectsTable.id, { onDelete: "cascade" }),
    userId: uuid("user_id")
      .notNull()
      .references(() => usersTable.id, { onDelete: "cascade" }),
    favicon: text("favicon").default("").notNull(),
    features: jsonb("features").$type<FeatureProps>().notNull().default({}),
    i18n: jsonb("i18n")
      .$type<{ defaultLocale: string; locales: string[] }>()
      .default({
        defaultLocale: "en",
        locales: ["en"],
      }),
    resources: jsonb("resources")
      .$type<{
        api: string;
        ipfs: string;
        staticResources: string;
      }>()
      .default({
        api: "",
        ipfs: "",
        staticResources: "",
      }),
    theme: jsonb("theme").$type<Config["theme"]>(),
    edge: jsonb("edge")
      .$type<{
        development: EdgeConfigValue;
        staging: EdgeConfigValue;
        testnet: EdgeConfigValue;
        beta: EdgeConfigValue;
        production: EdgeConfigValue;
      }>()
      .default({
        development: {
          featureFlags: defaultEdgeFeatureFlags,
          config: defaultEdgeConfig,
        },
        staging: {
          featureFlags: defaultEdgeFeatureFlags,
          config: defaultEdgeConfig,
        },
        testnet: {
          featureFlags: defaultEdgeFeatureFlags,
          config: defaultEdgeConfig,
        },
        beta: {
          featureFlags: defaultEdgeFeatureFlags,
          config: defaultEdgeConfig,
        },
        production: {
          featureFlags: defaultEdgeFeatureFlags,
          config: defaultEdgeConfig,
        },
      }),
    wallets: jsonb("wallets")
      .$type<{
        chains: number[];
        walletConnectProjectId: string;
        rpcNodes: Record<number, string>;
      }>()
      .default({
        chains: [11155111],
        walletConnectProjectId: "1ab8243664efca5123d41fe9cbde3c5e",
        rpcNodes: {
          1: "https://rpc.ankr.com/eth",
          137: "https://rpc.ankr.com/polygon",
          80001: "https://rpc.ankr.com/polygon_mumbai",
          11155111: "https://rpc.ankr.com/eth_sepolia",
        },
      }),
  },
  (table) => ({
    idIdx: index("configurationId_Idx").on(table.id),
    projectIdIdx: index("configurationProjectId_Idx").on(table.projectId),
  }),
);

export const configurationsRelations = relations(
  configurationsTable,
  ({ one }) => ({
    project: one(projectsTable, {
      fields: [configurationsTable.projectId],
      references: [projectsTable.id],
    }),
    creator: one(usersTable, {
      fields: [configurationsTable.userId],
      references: [usersTable.id],
    }),
  }),
);

export type ConfigurationRelations = Partial<{
  project: InferSelectModel<typeof projectsTable>;
  creator: InferSelectModel<typeof usersTable>;
}>;
export type Configuration = InferSelectModel<typeof configurationsTable> &
  ConfigurationRelations;
export type NewConfiguration = InferInsertModel<typeof configurationsTable>;
type FeatureProps = Record<
  string,
  | boolean
  | string
  | {
      enabled: boolean;
      props: {
        items: any[];
      };
    }
>;
