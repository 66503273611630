import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { pgTable, text, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "./base";
import { projectsTable } from "./project/project";

export const subscribeTable = pgTable("newsletter_subscription", {
  ...defaultSchema,
  email: text("email").unique().notNull(),
  projectId: uuid("project_id")
    .notNull()
    .references(() => projectsTable.id, { onDelete: "cascade" }),
});

export const subscribeRelations = relations(subscribeTable, ({ one }) => ({
  project: one(projectsTable, {
    fields: [subscribeTable.projectId],
    references: [projectsTable.id],
  }),
}));

export type SubscribeRelations = Partial<{
  project: InferSelectModel<typeof projectsTable>;
}>;
export type Subscribe = InferSelectModel<typeof subscribeTable> &
  SubscribeRelations;
export type NewSubscribe = InferInsertModel<typeof subscribeTable>;
