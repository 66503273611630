import { and, asc, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../..";
import { db } from "../..";
import type { NewProject, Project } from "../../schemas/project/project";
import { projectsTable } from "../../schemas/project/project";
import parseRelations from "../utils/parseRelations";
import { transactionOrDatabaseWrapper } from "../utils/transactions";

export async function getProjectByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.projectsTable.findFirst({
      where: and(eq(projectsTable.id, id), isNull(projectsTable.deletedAt)),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(projectsTable)
    .where(and(eq(projectsTable.id, id), isNull(projectsTable.deletedAt)))
    .limit(1);

  return result[0];
}

export async function getProjectBySlugDB(
  slug: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.projectsTable.findFirst({
      where: and(eq(projectsTable.slug, slug), isNull(projectsTable.deletedAt)),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(projectsTable)
    .where(and(eq(projectsTable.slug, slug), isNull(projectsTable.deletedAt)))
    .limit(1);

  return result[0];
}

export async function getProjectsByCreatorDB(
  creator: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.projectsTable.findMany({
      where: and(
        eq(projectsTable.userId, creator),
        isNull(projectsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(projectsTable)
    .where(
      and(eq(projectsTable.userId, creator), isNull(projectsTable.deletedAt)),
    );
}

export async function getProjectsDB(withModels?: Record<string, boolean>) {
  if (withModels) {
    return await db.query.projectsTable.findMany({
      where: isNull(projectsTable.deletedAt),
      with: parseRelations(withModels),
      orderBy: asc(projectsTable.createdAt),
    });
  }

  return await db
    .select()
    .from(projectsTable)
    .where(isNull(projectsTable.deletedAt))
    .orderBy(asc(projectsTable.createdAt));
}

export async function getProjectsIdsOnlyDB() {
  return await db
    .select({
      id: projectsTable.id,
    })
    .from(projectsTable)
    .where(isNull(projectsTable.deletedAt));
}

export async function createProjectDB(
  project: NewProject,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(projectsTable)
      .values(project)
      .returning();
    return result[0];
  }, transaction);
}

export async function updateProjectDB(
  project: Project,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(projectsTable)
      .set({ ...project, updatedAt: new Date().toISOString(), deletedAt: null })
      .where(eq(projectsTable.id, project.id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteProjectDB(
  id: string,
  creatorId: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .delete(projectsTable)
      // .update(projectsTable)
      // .set({ deletedAt: new Date().toISOString() })
      .where(and(eq(projectsTable.id, id), eq(projectsTable.userId, creatorId)))
      .returning();
  }, transaction);
}
