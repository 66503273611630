import type { FC, PropsWithChildren } from "react";
import React from "react";
import { useMemo } from "react";

import type { TabPanelProps } from "./types";

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({
  children,
  index,
  value,
  alwaysLoaded = false,
  className = "",
  ...props
}) => {
  const shouldRender = index === value;
  const alwaysLoadedButShouldNotRender = alwaysLoaded && !shouldRender;
  const tabPanelClassNames = useMemo(
    () =>
      [
        "flex",
        "w-full",
        alwaysLoadedButShouldNotRender && "invisible",
        alwaysLoadedButShouldNotRender && "h-0",
        alwaysLoadedButShouldNotRender && "fixed",
        alwaysLoadedButShouldNotRender && "top-[-100000px]",
        alwaysLoadedButShouldNotRender && "left-[-100000px]",
        alwaysLoaded && shouldRender && "visible",
        className,
      ]
        .filter((i) => i)
        .join(" "),
    [shouldRender, className],
  );

  if (alwaysLoaded) {
    return (
      <div className={tabPanelClassNames} {...props}>
        {children}
      </div>
    );
  }

  if (shouldRender) {
    return (
      <div className={tabPanelClassNames} {...props}>
        {children}
      </div>
    );
  }

  return null;
};

export default TabPanel;
