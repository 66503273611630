import { and, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../";
import { db } from "../";
import type {
  NewSocialNetwork,
  SocialNetwork,
} from "../schemas/socialNetworks";
import {
  SocialNetworkEntity,
  socialNetworkTable,
} from "../schemas/socialNetworks";
import parseRelations from "./utils/parseRelations";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

export async function getSocialNetworkByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.socialNetworkTable.findFirst({
      where: and(
        eq(socialNetworkTable.id, id),
        isNull(socialNetworkTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(socialNetworkTable)
    .where(
      and(eq(socialNetworkTable.id, id), isNull(socialNetworkTable.deletedAt)),
    );
}

export async function getSocialNetworksDB(
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.socialNetworkTable.findMany({
      where: isNull(socialNetworkTable.deletedAt),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(socialNetworkTable)
    .where(isNull(socialNetworkTable.deletedAt));
}

export async function getSocialNetworkByProjectIdDB(projectId: string) {
  const result = await db
    .select()
    .from(socialNetworkTable)
    .where(
      and(
        eq(socialNetworkTable.entityId, projectId),
        eq(socialNetworkTable.entityType, SocialNetworkEntity.Project),
        isNull(socialNetworkTable.deletedAt),
      ),
    );
  return result[0];
}

export async function createSocialNetworkDB(
  newSocialNetwork: NewSocialNetwork,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(socialNetworkTable)
      .values(newSocialNetwork)
      .returning();
    return result[0];
  }, transaction);
}

export async function updateSocialNetworkDB(
  socialNetwork: SocialNetwork,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(socialNetworkTable)
      .values({
        ...socialNetwork,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        deletedAt: null,
      })
      .onConflictDoUpdate({
        target: socialNetworkTable.id,
        set: {
          ...socialNetwork,
          updatedAt: new Date().toISOString(),
          deletedAt: null,
        },
        where: eq(socialNetworkTable.id, socialNetwork.id),
      })
      .returning();
    return result[0];
  }, transaction);
}

export async function upsertSocialNetworkDB(
  socialNetwork: SocialNetwork,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    if (socialNetwork?.id) {
      const result = await trxOrDb
        .update(socialNetworkTable)
        .set({
          ...socialNetwork,
          updatedAt: new Date().toISOString(),
          deletedAt: null,
        })
        .where(eq(socialNetworkTable.id, socialNetwork.id))
        .returning();
      return result[0];
    }

    const result = await trxOrDb
      .insert(socialNetworkTable)
      .values({
        ...socialNetwork,
        createdAt: new Date().toISOString(),
        updatedAt: null,
        deletedAt: null,
      })
      .returning();

    return result[0];
  }, transaction);
}

export async function deleteSocialNetworkDB(
  id: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(socialNetworkTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(socialNetworkTable.id, id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteSocialNetworkByProjectIdDB(
  projectId: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(socialNetworkTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(socialNetworkTable.entityId, projectId))
      .returning();
    return result[0];
  }, transaction);
}
