import type { ExtractTablesWithRelations } from "drizzle-orm";
import type {
  NodePgClient,
  NodePgTransaction,
} from "drizzle-orm/node-postgres";
import { drizzle } from "drizzle-orm/node-postgres";

import { Pool } from "./clients/pool";
import * as schema from "./schemas";

export const Database = (pgClient: NodePgClient) => {
  return drizzle(pgClient, {
    schema,
    // logger: true,
  });
};

const config = {
  host: (process.env.DATABASE_HOST || "%ENV_DATABASE_HOST%") as string,
  port: parseInt(
    (process.env.DATABASE_PORT || "%ENV_DATABASE_PORT%") as string,
  ) as number,
  user: (process.env.DATABASE_USERNAME || "%ENV_DATABASE_USERNAME%") as string,
  password: (process.env.DATABASE_PASSWORD ||
    "%ENV_DATABASE_PASSWORD%") as string,
  database: (process.env.DATABASE_DATABASE ||
    "%ENV_DATABASE_DATABASE%") as string,
  ssl: process.env.DATABASE_SSL === "true",
};
const pool = Pool(config);
export const db = Database(pool);

export * from "./clients";
export * from "./handlers";
export * from "./logic";
export * from "./schemas";
export * from "drizzle-orm";
export * from "drizzle-orm/node-postgres";
export type Database = typeof db;
export type Schemas = typeof schema;
export type Transaction = NodePgTransaction<
  Schemas,
  ExtractTablesWithRelations<Schemas>
>;
// export type Transaction = PgTransaction<
//   NodePgQueryResultHKT,
//   Schemas,
//   ExtractTablesWithRelations<Schemas>
// >;
