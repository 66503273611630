import type { FC, PropsWithChildren } from "react";
import React from "react";

import Button from "../Button";
import type { TabProps } from "./types";

export const Tab: FC<PropsWithChildren<TabProps>> = ({
  selected = false,
  bgColor = "inherit",
  color = "inherit",
  hoverColor = "secondary",
  skewed = false,
  borderColor,
  children,
  onClick,
  variant,
  ...props
}) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      variant={variant}
      color={bgColor}
      noShadow
      skewed={skewed}
      className={[
        "rounded-none",
        "font-semibold",
        "border",
        "!shadow-none",
        "whitespace-nowrap",
        borderColor && `border-${borderColor}`,
        selected && `!text-${color}`,
        selected && `hover:!text-${hoverColor}`,
        selected && skewed && `bg-${bgColor}`,
        !selected && `!text-${hoverColor}`,
        !selected && `hover:!text-${color}`,
      ]
        .filter((i) => i)
        .join(" ")}
    >
      <div className={[skewed && `-skew-x-12`].filter((i) => i).join(" ")}>
        {children}
      </div>
    </Button>
  );
};

export default Tab;
