import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { pgTable, text } from "drizzle-orm/pg-core";

import defaultSchema from "./base";
import { campaignsTable } from "./campaign/campaign";
import { configurationsTable } from "./configurations";
import { projectsTable } from "./project/project";

export const usersTable = pgTable("user", {
  ...defaultSchema,
  email: text("email").default(""),
  password: text("password"),
  username: text("username"), // TODO: UNIQUE constraint not yet supported
});

export const usersRelations = relations(usersTable, ({ many }) => ({
  campaigns: many(campaignsTable),
  projects: many(projectsTable),
  configurations: many(configurationsTable),
}));

export type UserRelations = Partial<{
  campaigns: InferSelectModel<typeof campaignsTable>[];
  projects: InferSelectModel<typeof projectsTable>[];
  configurations: InferSelectModel<typeof configurationsTable>[];
}>;
export type User = InferSelectModel<typeof usersTable> & UserRelations;
export type NewUser = InferInsertModel<typeof usersTable>;
