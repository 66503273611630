import { and, eq, isNull, sql } from "drizzle-orm";

import type { Transaction } from "../";
import { db } from "../";
import type { Network, NewNetwork } from "../schemas/network";
import { networkTable } from "../schemas/network";
import parseRelations from "./utils/parseRelations";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

const DEFAULT_SELECT = {
  id: networkTable.id,
  createdAt: networkTable.createdAt,
  coinTokenSymbols: networkTable.coinTokenSymbols,
  name: networkTable.name,
  chainId: networkTable.chainId,
  updatedAt: networkTable.updatedAt,
  deletedAt: networkTable.deletedAt,
  rpcURLs: networkTable.rpcURLs,
};

export async function getNetworkByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.networkTable.findFirst({
      columns: {
        id: true,
        createdAt: true,
        coinTokenSymbols: true,
        name: true,
        chainId: true,
        updatedAt: true,
        deletedAt: true,
        rpcURLs: true,
      },
      where: and(eq(networkTable.id, id), isNull(networkTable.deletedAt)),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select(DEFAULT_SELECT)
    .from(networkTable)
    .where(and(eq(networkTable.id, id), isNull(networkTable.deletedAt)));
  return result[0];
}

export async function getNetworkAllInfoByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.networkTable.findFirst({
      where: and(eq(networkTable.id, id), isNull(networkTable.deletedAt)),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(networkTable)
    .where(and(eq(networkTable.id, id), isNull(networkTable.deletedAt)));
  return result[0];
}

export async function getNetworksDB(withModels?: Record<string, boolean>) {
  if (withModels) {
    return await db.query.networkTable.findMany({
      columns: {
        id: true,
        createdAt: true,
        coinTokenSymbols: true,
        name: true,
        chainId: true,
        updatedAt: true,
        deletedAt: true,
        rpcURLs: true,
      },
      where: isNull(networkTable.deletedAt),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select(DEFAULT_SELECT)
    .from(networkTable)
    .where(isNull(networkTable.deletedAt));
}

export async function getNetworksAllInfoDB(
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.networkTable.findMany({
      where: isNull(networkTable.deletedAt),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(networkTable)
    .where(isNull(networkTable.deletedAt));
}

export async function getNetworkByNameDB(name: string) {
  const result = await db
    .select()
    .from(networkTable)
    .where(and(eq(networkTable.name, name), isNull(networkTable.deletedAt)));
  return result[0];
}

export async function createNetworkDB(
  network: NewNetwork,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(networkTable)
      .values(network)
      .returning();
    return result[0];
  }, transaction);
}

export async function updateNetworkDB(
  network: Network,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(networkTable)
      .set({
        ...network,
        updatedAt: sql`now()`,
      })
      .where(eq(networkTable.id, network.id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteNetworkDB(id: string, transaction?: Transaction) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(networkTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(networkTable.id, id))
      .returning();
    return result[0];
  }, transaction);
}
