import { and, asc, eq, isNull, sql } from "drizzle-orm";

import type { Transaction } from "../";
import { db } from "../";
import type { NewTier, Tier } from "../schemas/tiers";
import { tiersTable } from "../schemas/tiers";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

export async function getTierByIdDB(id: string) {
  const result = await db
    .select()
    .from(tiersTable)
    .where(and(eq(tiersTable.id, id), isNull(tiersTable.deletedAt)))
    .limit(1);

  return result[0];
}

export async function getTiersDB() {
  return await db.select().from(tiersTable).where(isNull(tiersTable.deletedAt));
}

export async function getTiersByCampaignIdDB(campaignId: string) {
  return await db
    .select()
    .from(tiersTable)
    .where(
      and(eq(tiersTable.campaignId, campaignId), isNull(tiersTable.deletedAt)),
    )
    .orderBy(asc(tiersTable.order));
}

export async function createTierDB(
  newTier: NewTier,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb.insert(tiersTable).values(newTier).returning();

    return result[0];
  }, transaction);
}

export async function createTiersDB(
  newTiers: NewTier[],
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(tiersTable)
      .values(newTiers)
      .returning();

    return result;
  }, transaction);
}

export async function updateTierDB(tier: Tier, transaction?: Transaction) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(tiersTable)
      .set({
        ...tier,
        updatedAt: sql`now()`,
      })
      .where(eq(tiersTable.id, tier.id))
      .returning();

    return result[0];
  }, transaction);
}

export async function upsertTierDB(
  payload: Tier | NewTier,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(tiersTable)
      .values({
        ...payload,
        createdAt: new Date().toISOString(),
        updatedAt: null,
        deletedAt: null,
        merkleProof: payload.merkleProof || null,
      })
      .onConflictDoUpdate({
        target: tiersTable.id,
        set: {
          ...payload,
          updatedAt: new Date().toISOString(),
          merkleProof: payload.merkleProof || null,
        },
        where: eq(tiersTable.id, (payload as Tier).id),
      })
      .returning();

    return result[0];
  }, transaction);
}

export async function deleteTierByIdDB(id: string, transaction?: Transaction) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(tiersTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(tiersTable.id, id))
      .returning();

    return result[0];
  }, transaction);
}

export async function deleteTiersInBatchByIdsDB(
  ids: string[],
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb.transaction(async (tx: any) => {
      return await Promise.all(
        ids.map(
          async (id) =>
            await tx
              .update(tiersTable)
              .set({
                deletedAt: new Date().toISOString(),
                updatedAt: sql`now()`,
              })
              .where(eq(tiersTable.id, id))
              .returning(),
        ),
      );
    });
  }, transaction);
}

export async function deleteTiersByCampaignIdDB(
  campaignId: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .update(tiersTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(
        and(
          eq(tiersTable.campaignId, campaignId),
          isNull(tiersTable.deletedAt),
        ),
      )
      .returning();
  }, transaction);
}
