import { sql } from "drizzle-orm";
import { customType } from "drizzle-orm/pg-core";

const customTimestamp = customType<{
  data: string;
  driverData: Date;
  config: { withTimezone: boolean; precision?: number };
}>({
  dataType(config) {
    const precision =
      typeof config?.precision !== "undefined" ? ` (${config.precision})` : "";
    return `timestamp${precision}${
      config?.withTimezone ? " with time zone" : ""
    }`;
  },
  fromDriver(value: Date) {
    return new Date(value).toISOString();
  },
  toDriver(value?: string) {
    if (!value) {
      return sql`NULL`;
    }
    return new Date(value);
  },
});

export default customTimestamp;
