import {
  createProjectLaunchpadDB,
  db,
  SocialNetworkEntity,
  upsertProjectLaunchpadDB,
} from "../";
import {
  createConfigurationDB,
} from "../handlers/configurations";
import {
  createProjectDB,
  deleteProjectDB,
  updateProjectDB,
} from "../handlers/project/project";
import {
  createSocialNetworkDB,
  updateSocialNetworkDB,
} from "../handlers/socialNetworks";
import type {
  NewProjectWithRelations,
  Project,
  ProjectWithRelations,
} from "../schemas/project/project";

export const createProjectLogicDB = async (
  payload: NewProjectWithRelations,
) => {
  return await db.transaction(async (trx) => {
    try {
      const { socialNetwork, launchpad, ...project } = payload;
      const newProject = (await createProjectDB(project, trx)) as Project;

      const newSocialNetwork = await createSocialNetworkDB(
        {
          ...socialNetwork,
          entityId: newProject.id,
          entityType: SocialNetworkEntity.Project,
        },
        trx,
      );
      const newProjectLaunchpad = launchpad
        ? await createProjectLaunchpadDB(
            {
              ...launchpad,
              projectId: newProject.id,
            },
            trx,
          )
        : null;

      await createConfigurationDB(
        {
          userId: payload.userId,
          projectId: newProject.id,
        },
        trx,
      );

      return {
        ...newProject,
        socialNetwork: newSocialNetwork,
        launchpad: newProjectLaunchpad,
      };
    } catch (error) {
      console.error(error);
      trx.rollback();
    }
  });
};

export const updateProjectLogicDB = async (payload: ProjectWithRelations) => {
  return await db.transaction(async (trx: any) => {
    try {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { socialNetwork, campaigns, launchpad, ...project } = payload;

      const newProject = (await updateProjectDB(project, trx)) as Project;

      const newSocialNetwork = socialNetwork
        ? await updateSocialNetworkDB(
            {
              ...socialNetwork,
              entityId: payload.id,
              entityType: SocialNetworkEntity.Project,
            },
            trx,
          )
        : null;

      const newProjectLaunchpad = launchpad
        ? await upsertProjectLaunchpadDB(
            { ...launchpad, projectId: payload.id },
            trx,
          )
        : null;

      return {
        ...newProject,
        socialNetwork: newSocialNetwork,
        launchpad: newProjectLaunchpad,
      };
    } catch (error) {
      console.error(error);
      trx.rollback();
    }
  });
};

export const deleteProjectLogicDB = async (id: string, creator: string) => {
  return await db.transaction(async (trx: any) => {
    const project = await deleteProjectDB(id, creator, trx);
    // await deleteSocialNetworkByProjectIdDB(id, trx);
    // // TODO: delete campaigns, configurations, etc

    // const campaigns = await getCampaignsByProjectIdDB(id);
    // await deleteCampaignsInBatchByIdsDB(
    //   campaigns.map((campaign) => campaign.id),
    //   trx,
    // );

    // await deleteConfigurationByProjectIdDB(id, creator, trx);

    // await deleteSocialNetworkByProjectIdDB(id, trx);

    return project;
  });
};
