import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { pgTable, text, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "../base";
import { projectsTable } from "./project";

export const projectLaunchpadTable = pgTable("project_launchpad", {
  ...defaultSchema,
  projectId: uuid("project_id")
    .notNull()
    .references(() => projectsTable.id, { onDelete: "cascade" }),
  address: text("address").$type<`0x${string}`>().notNull(),
  network: text("network").notNull(),
});

export const projectLaunchpadRelations = relations(
  projectLaunchpadTable,
  ({ one }) => ({
    project: one(projectsTable, {
      fields: [projectLaunchpadTable.projectId],
      references: [projectsTable.id],
    }),
  }),
);

type ProjectLaunchpadTable = typeof projectLaunchpadTable;
export type ProjectLaunchpad = InferSelectModel<ProjectLaunchpadTable>;
export type NewProjectLaunchpad = InferInsertModel<ProjectLaunchpadTable>;
