import { and, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../";
import { db } from "../";
import type {
  Contract,
  NewContract,
} from "../schemas/campaign/campaignContract";
import { campaignContractTable } from "../schemas/campaign/campaignContract";
import parseRelations from "./utils/parseRelations";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

export async function getContractByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignContractTable.findFirst({
      where: and(
        eq(campaignContractTable.id, id),
        isNull(campaignContractTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(campaignContractTable)
    .where(
      and(
        eq(campaignContractTable.id, id),
        isNull(campaignContractTable.deletedAt),
      ),
    );
  return result[0];
}

export async function getContractByCampaignDB(
  campaign: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignContractTable.findFirst({
      where: and(
        eq(campaignContractTable.campaignId, campaign),
        isNull(campaignContractTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(campaignContractTable)
    .where(
      and(
        eq(campaignContractTable.campaignId, campaign),
        isNull(campaignContractTable.deletedAt),
      ),
    );
  return result[0];
}

export async function getContractsDB(withModels?: Record<string, boolean>) {
  if (withModels) {
    return await db.query.campaignContractTable.findMany({
      where: isNull(campaignContractTable.deletedAt),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(campaignContractTable)
    .where(isNull(campaignContractTable.deletedAt));
}

export async function createContractDB(
  data: NewContract,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(campaignContractTable)
      .values(data)
      .returning();
    return result[0];
  }, transaction);
}

export async function updateContractDB(
  contract: Contract,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(campaignContractTable)
      .set({
        ...contract,
        updatedAt: new Date().toISOString(),
        deletedAt: null,
      })
      .where(eq(campaignContractTable.id, contract.id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteContractDB(id: string, transaction?: Transaction) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .update(campaignContractTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(campaignContractTable.id, id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteContractsByCampaignIdDB(
  campaign: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .update(campaignContractTable)
      .set({ deletedAt: new Date().toISOString() })
      .where(eq(campaignContractTable.campaignId, campaign));
  }, transaction);
}
