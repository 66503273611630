import { index, pgEnum, pgTable, text, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "./base";

export enum SocialNetworkEntity {
  Project = "project",
  Campaign = "campaign",
}

export const entityTypeEnum = pgEnum("social_network_entity_type", [
  SocialNetworkEntity.Project,
  SocialNetworkEntity.Campaign,
]);

export const socialNetworkTable = pgTable(
  "social_network",
  {
    ...defaultSchema,
    // polymorphic relation
    entityId: uuid("entity_id").notNull(),
    entityType: entityTypeEnum("entity_type")
      .default(SocialNetworkEntity.Project)
      .notNull(),
    // links
    website: text("website").default(""),
    whitepaper: text("whitepaper").default(""),
    telegram: text("telegram").default(""),
    twitter: text("twitter").default(""),
    discord: text("discord").default(""),
    facebook: text("facebook").default(""),
    github: text("github").default(""),
    instagram: text("instagram").default(""),
    linkedin: text("linkedin").default(""),
    medium: text("medium").default(""),
    reddit: text("reddit").default(""),
    tiktok: text("tiktok").default(""),
    youtube: text("youtube").default(""),
    coingecko: text("coingecko").default(""),
    coinmarketcap: text("coinmarketcap").default(""),
    opensea: text("opensea").default(""),
    rarible: text("rarible").default(""),
    blur: text("blur").default(""),
  },
  (table) => ({
    idIdx: index("id_Idx").on(table.id),
  }),
);

export type SocialNetwork = typeof socialNetworkTable.$inferSelect;
export type NewSocialNetwork = typeof socialNetworkTable.$inferInsert;
