import { sql } from "drizzle-orm";
import { uuid } from "drizzle-orm/pg-core";

import timestamp from "../types/timestamp";

const defaultSchema = {
  id: uuid("id").primaryKey().defaultRandom(),
  createdAt: timestamp("created_at")
    .default(sql`now()`)
    .notNull(),
  updatedAt: timestamp("updated_at").default(sql`now()`),
  deletedAt: timestamp("deleted_at"),
};

export default defaultSchema;

export type Base = {
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
};

export type BaseFields = keyof Base;
