import { and, eq, isNull } from "drizzle-orm";

import type { Transaction } from "../..";
import { db, projectsTable } from "../..";
import type {
  NewProjectReward,
  ProjectReward,
} from "../../schemas/project/projectReward";
import { projectRewardTable } from "../../schemas/project/projectReward";
import { transactionOrDatabaseWrapper } from "../utils/transactions";

export async function getProjectRewardsDB() {
  return await db.query.projectRewardTable.findMany({
    where: and(isNull(projectRewardTable.deletedAt)),
  });
}

export async function getProjectRewardByProjectIdDB(projectId: string) {
  return await db.query.projectRewardTable.findMany({
    where: and(
      eq(projectRewardTable.projectId, projectId),
      isNull(projectRewardTable.deletedAt),
    ),
  });
}

export async function getProjectRewardByProjectSlugDB(slug: string) {
  const project = await db.query.projectsTable.findFirst({
    where: eq(projectsTable.slug, slug),
  });

  if (!project || !project?.id) {
    throw new Error("Could not find project with given slug");
  }

  return await db.query.projectRewardTable.findMany({
    where: and(
      eq(projectRewardTable.projectId, project.id),
      isNull(projectRewardTable.deletedAt),
    ),
  });
}

export async function getProjectRewardByNetworkIdDB(networkId: string) {
  return await db.query.projectRewardTable.findMany({
    where: and(
      eq(projectRewardTable.networkId, networkId),
      isNull(projectRewardTable.deletedAt),
    ),
  });
}

export async function getProjectRewardByProjectAndNetworkIdDB(
  projectId: string,
  networkId: string,
) {
  return await db.query.projectRewardTable.findFirst({
    where: and(
      eq(projectRewardTable.projectId, projectId),
      eq(projectRewardTable.networkId, networkId),
      isNull(projectRewardTable.deletedAt),
    ),
  });
}

export async function createProjectRewardDB(
  payload: NewProjectReward,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(projectRewardTable)
      .values(payload)
      .returning();
    return result[0];
  }, transaction);
}

export async function upsertProjectRewardDB(
  payload: ProjectReward,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(projectRewardTable)
      .values({
        ...payload,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        deletedAt: null,
      })
      .onConflictDoUpdate({
        target: projectRewardTable.id,
        set: {
          ...payload,
          updatedAt: new Date().toISOString(),
          deletedAt: null,
        },
        where: eq(projectRewardTable.id, payload.id),
      })
      .returning();

    return result[0];
  }, transaction);
}
