import { and, asc, eq, ilike, isNull, or, sql } from "drizzle-orm";

import type { Transaction } from "../";
import { db, projectsTable, tiersTable } from "../";
import type {
  Campaign,
  CampaignType,
  NewCampaign,
} from "../schemas/campaign/campaign";
import { CampaignEnum, campaignsTable } from "../schemas/campaign/campaign";
import parseRelations from "./utils/parseRelations";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

export async function getCampaignsDB(withModels?: Record<string, boolean>) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: isNull(campaignsTable.deletedAt),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(campaignsTable)
    .where(isNull(campaignsTable.deletedAt));
}

export async function getCampaignByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findFirst({
      where: and(eq(campaignsTable.id, id), isNull(campaignsTable.deletedAt)),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(campaignsTable)
    .where(and(eq(campaignsTable.id, id), isNull(campaignsTable.deletedAt)));
  return result[0];
}

export async function getCampaignByIdAndProjectIdDB(
  projectId: string,
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findFirst({
      where: and(
        eq(campaignsTable.id, id),
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(campaignsTable)
    .where(
      and(
        eq(campaignsTable.id, id),
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
    );
  return result[0];
}

export async function getCampaignAndProjectIdDB(
  projectId: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: and(
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(campaignsTable)
    .where(
      and(
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
    );
  return result;
}

export async function getCampaignsByCreatorDB(
  creator: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: and(
        eq(campaignsTable.userId, creator),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(campaignsTable)
    .where(
      and(eq(campaignsTable.userId, creator), isNull(campaignsTable.deletedAt)),
    );
}

export async function getCampaignsByTypeDB(
  type: CampaignType,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: and(
        eq(campaignsTable.type, type),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(campaignsTable)
    .where(
      and(eq(campaignsTable.type, type), isNull(campaignsTable.deletedAt)),
    );
}

export async function getCampaignsByProjectIdDB(
  projectId: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: and(
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(campaignsTable)
    .where(
      and(
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
    );
}

export async function getCampaignsByProjectIdFilteredByTypeDB(
  projectId: string,
  type: CampaignType,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: and(
        eq(campaignsTable.projectId, projectId),
        eq(campaignsTable.type, type),
        isNull(campaignsTable.deletedAt),
      ),
      with: withModels,
    });
  }

  return await db
    .select()
    .from(campaignsTable)
    .where(
      and(
        eq(campaignsTable.projectId, projectId),
        eq(campaignsTable.type, type),
        isNull(campaignsTable.deletedAt),
      ),
    );
}

export async function getCampaignsTierDB(withModels?: Record<string, boolean>) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      where: or(
        eq(campaignsTable.type, CampaignEnum.TIER),
        eq(campaignsTable.type, CampaignEnum.PUBLIC_TIER),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
      orderBy: asc(tiersTable.createdAt),
    });
  }

  return await db
    .select()
    .from(campaignsTable)
    .where(
      or(
        eq(campaignsTable.type, CampaignEnum.TIER),
        eq(campaignsTable.type, CampaignEnum.PUBLIC_TIER),
        isNull(campaignsTable.deletedAt),
      ),
    )
    .orderBy(asc(tiersTable.createdAt));
}

export async function getCampaignsByProjectIdOnlyDB(
  projectId: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.campaignsTable.findMany({
      columns: {
        id: true,
        projectId: true,
      },
      where: and(
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select({
      id: campaignsTable.id,
      projectId: campaignsTable.projectId,
    })
    .from(campaignsTable)
    .where(
      and(
        eq(campaignsTable.projectId, projectId),
        isNull(campaignsTable.deletedAt),
      ),
    );
}

export async function createCampaignDB(
  campaign: NewCampaign,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(campaignsTable)
      .values(campaign)
      .returning();
    return result[0];
  }, transaction);
}

export async function updateCampaignDB(
  id: string,
  campaign: Partial<Campaign>,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb: any) => {
    const result = await trxOrDb
      .update(campaignsTable)
      .set({
        ...campaign,
        updatedAt: new Date().toISOString(),
        deletedAt: null,
      })
      .where(eq(campaignsTable.id, id))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteCampaignDB(
  id: string,
  creator: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const campaign = await getCampaignByIdDB(id);
    if (campaign === undefined) {
      throw new Error(`Campaign with id ${id} not found`);
    }

    const result = await trxOrDb
      // .update(campaignsTable)
      // .set({
      //   deletedAt: new Date().toISOString(),
      // })
      .delete(campaignsTable)
      .where(and(eq(campaignsTable.id, id), eq(campaignsTable.userId, creator)))
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteCampaignsInBatchByIdsDB(
  ids: string[],
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb.transaction(async (tx: any) => {
      return await Promise.all(
        ids.map(
          async (id) =>
            await tx
              .update(campaignsTable)
              .set({
                deletedAt: new Date().toISOString(),
                updatedAt: sql`now()`,
              })
              .where(eq(campaignsTable.id, id))
              .returning(),
        ),
      );
    });
  }, transaction);
}

export const getCampaignsByProjectIdFullDB = async (projectId: string) => {
  return await db.query.campaignsTable.findMany({
    where: and(
      eq(campaignsTable.projectId, projectId),
      isNull(campaignsTable.deletedAt),
    ),
  });
};

export const getCampaignsByProjectSlugFullDB = async (slug: string) => {
  const project = await db.query.projectsTable.findFirst({
    where: eq(projectsTable.slug, slug),
  });

  if (!project) {
    throw new Error("Could not fetch campaigns by project slug");
  }

  return await db.query.campaignsTable.findMany({
    where: and(
      eq(campaignsTable.projectId, project.id),
      isNull(campaignsTable.deletedAt),
    ),
  });
};

export const getCampaignBySlugDB = async (slug: string, projectId?: string) => {
  return await db.query.campaignsTable.findFirst({
    where: and(
      ilike(campaignsTable.slug, slug),
      isNull(campaignsTable.deletedAt),
      projectId ? eq(campaignsTable.projectId, projectId) : undefined,
    ),
    with: {
      contracts: {
        where: (contracts) => isNull(contracts.deletedAt),
        with: {
          network: true,
        },
      },
      tiers: {
        where: (tiers) => isNull(tiers.deletedAt),
      },
      contents: {
        where: (contents) => isNull(contents.deletedAt),
      },
      socialNetwork: {
        columns: {
          createdAt: false,
          updatedAt: false,
          deletedAt: false,
          id: false,
          entityId: false,
          entityType: false,
        },
      },
    },
  });
};

export const getCampaignsByProjectIdGroupedByTypeDB = async (
  projectId: string,
) => {
  const campaigns = await db.query.campaignsTable.findMany({
    where: and(
      eq(campaignsTable.projectId, projectId),
      isNull(campaignsTable.deletedAt),
    ),
    with: {
      contracts: {
        where: (contracts) => isNull(contracts.deletedAt),
      },
      tiers: {
        where: (tiers) => isNull(tiers.deletedAt),
      },
    },
  });

  return campaigns;
};

export const getCampaignByIdCleanupDB = async (id: string) => {
  return await db.query.campaignsTable.findFirst({
    where: and(eq(campaignsTable.id, id), isNull(campaignsTable.deletedAt)),
    with: {
      contracts: {
        where: (contracts) => isNull(contracts.deletedAt),
        with: {
          network: true,
        },
      },
      tiers: {
        where: (tiers) => isNull(tiers.deletedAt),
      },
      contents: {
        where: (contents) => isNull(contents.deletedAt),
      },
      socialNetwork: true,
    },
  });
};
