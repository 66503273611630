import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { boolean, pgTable, text, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "./base";
import { networkTable } from "./network";

export const networkTokenTable = pgTable("network_token", {
  ...defaultSchema,
  networkId: uuid("network_id")
    .notNull()
    .references(() => networkTable.id, { onDelete: "cascade" }),
  address: text("address").$type<`0x${string}`>().notNull(),
  icon: text("icon").notNull(),
  symbol: text("symbol").notNull(),
  isNative: boolean("is_native").default(false).notNull(),
});

export const networkTokenTableRelations = relations(
  networkTokenTable,
  ({ one }) => ({
    network: one(networkTable, {
      fields: [networkTokenTable.networkId],
      references: [networkTable.id],
    }),
  }),
);

type NetworkTokenTable = typeof networkTokenTable;
export type NetworkToken = InferSelectModel<NetworkTokenTable>;
export type NewNetworkToken = InferInsertModel<NetworkTokenTable>;
