import { getWindow } from "@whitelabel-engine/ui/utils/window";

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const convertUTCDateToDate = (utcDate: Date | string | number): Date => {
  if (typeof utcDate === "string" || utcDate instanceof Date) {
    return new Date(utcDate);
  }

  if (typeof utcDate === "number") {
    return new Date(utcDate * 1000);
  }

  throw new Error("Invalid date");
};

export const convertUTCDateToLocaleString = (
  utcDate: Date | string | number,
  customTimeZone?: string,
): string => {
  const date = convertUTCDateToDate(utcDate);
  const timeZone =
    customTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone; // Use browser's locale or fallback to "en-US"
  const options = {
    weekday: "long" as const, // Ensure weekday property has a valid value
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
    hour: "numeric" as const,
    minute: "numeric" as const,
    second: "numeric" as const,
    timeZone: timeZone,
  };
  return date.toLocaleString(undefined, options);
};

export const convertUTCDateToDateYYYYMMMDDHHMM = (
  utcDate: Date | string | number,
) => {
  const date = convertUTCDateToDate(utcDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;

  return formattedDate;
};

export const convertUTCDateToCurrentBrowserFormat = (
  utcDate: Date | string | number,
) => {
  // Convert the UTC date to a Date object
  const date = new Date(utcDate);

  // Use the user's locale to format the date and time
  const locale = getWindow()?.navigator?.language ?? "en-GB"; // Detects the user's browser language setting
  const formattedDate = new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);

  return formattedDate;
};

export function convertTextToValidURL(text: string) {
  let urlSlug = text.trim().toLowerCase().replace(/\s+/g, "-");

  urlSlug = urlSlug.replace(/[^a-z0-9-]/g, "");

  urlSlug = urlSlug.replace(/-+/g, "-");

  return urlSlug;
}

export function addEllipsisInText(description: string, trimLength: number) {
  if (!trimLength) {
    return description;
  }

  return `${description?.split?.("")?.splice(0, trimLength).join("")}${
    description?.length > trimLength ? "..." : ""
  }`;
}

export function formatNumber(number?: string | number) {
  if (number) {
    const _number = (typeof number === "string" ? +number : number).toFixed(2);
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      useGrouping: true,
    }).format(+_number);
  }

  return number ?? 1;
}
