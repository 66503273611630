import type { FC, PropsWithChildren, ReactElement } from "react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Children, cloneElement, useMemo, useRef } from "react";
import { tv } from "tailwind-variants";

import Tab from "./Tab";
import type { TabListProps, TabProps } from "./types";

const tabList = tv({
  slots: {
    wrapper: [
      "flex",
      "w-full",
      "flex-col",
      "overflow-scroll",
      "sm:overflow-hidden",
      "pb-4",
    ],
    items: ["flex", "w-full", "justify-start"],
    indicator: [],
    track: ["relative", "mt-1", "flex", "h-1", "w-screen"],
  },
  variants: {
    showIndicator: {
      true: {
        indicator: ["absolute", "h-0.5", "top-0.5", "left-0"],
      },
    },
    showIndicatorTrack: {
      true: {
        track: ["border-b-[1px]", "border-solid"],
      },
    },
  },
});

export const TabList: FC<PropsWithChildren<TabListProps>> = ({
  children,
  disabled,
  textColor = "primary",
  hoverTextColor = "secondary",
  value = 0,
  fullWidth = false,
  indicatorColor = "primary",
  indicatorClassName = "",
  className = "",
  wrapperClassName = "",
  showIndicator = true,
  showIndicatorTrack = true,
  indicatorFineLineColor = "neutral",
  tabListBorderColor = "transparent",
  prefixContent,
  suffixContent,
  onChange,
  skewed,
  tabListItemVariant,
  tabListBgColor,
  ...props
}) => {
  const [indicatorWidths, setIndicatorWidths] = useState<number[]>([]);
  const tabsWrapperRef = useRef<HTMLDivElement>(null);
  const prefixContentRef = useRef<HTMLDivElement>(null);
  const childrenCount = useMemo(
    () =>
      Children.map(children, (child) => {
        if ((child as ReactElement)?.type !== Tab) {
          return null;
        }
        return child;
      })?.filter((i) => i !== null)?.length ?? 0,
    [children],
  );
  const maxTrackWidth = useMemo(
    () =>
      indicatorWidths.reduce((acc, curr) => acc + curr, 0) +
      (prefixContentRef?.current?.clientWidth ?? 0),
    [indicatorWidths, prefixContentRef],
  );

  const indicatorPosition = useMemo(
    () =>
      indicatorWidths.slice(0, value).reduce((acc, curr) => acc + curr, 0) +
      (prefixContentRef?.current?.clientWidth ?? 0),
    [indicatorWidths, prefixContentRef, value],
  );

  const { wrapper, indicator, items, track } = useMemo(() => {
    const { wrapper, indicator, items, track } = tabList();
    return {
      wrapper: wrapper({ className: wrapperClassName }),
      indicator: indicator({
        showIndicator,
        className: showIndicator
          ? `bg-${indicatorColor} ${indicatorClassName}`
          : indicatorClassName,
      }),
      items: items({ className }),
      track: track({
        showIndicatorTrack,
        className: `border-${indicatorFineLineColor} w-full`,
      }),
    };
  }, [
    wrapperClassName,
    className,
    showIndicator,
    showIndicatorTrack,
    indicatorColor,
    indicatorClassName,
  ]);

  const handleOnClick = (index: number, onClick?: Function) => () => {
    onChange?.(index);
    onClick?.();
  };

  const tabItems = useMemo(
    () =>
      Children.map(
        children as ReactElement<TabProps>[],
        (child: ReactElement<TabProps>, index) => {
          return cloneElement(child, {
            ...child?.props,
            onClick: handleOnClick(index, child?.props?.onClick),
            disabled,
            fullWidth,
            skewed,
            color: textColor,
            hoverColor: hoverTextColor,
            selected: value === index,
            borderColor: tabListBorderColor,
            bgColor: tabListBgColor,
            variant: tabListItemVariant,
          });
        },
      ),
    [children, disabled, fullWidth, textColor, hoverTextColor, value],
  );

  const tabItemContent = useMemo(() => {
    if (!prefixContent && !suffixContent) {
      return (
        <div className={items} key={value} ref={tabsWrapperRef} {...props}>
          {tabItems}
        </div>
      );
    }

    return (
      <div className="flex w-full justify-between">
        {prefixContent && (
          <div ref={prefixContentRef} className="flex sm:hidden">
            {prefixContent}
          </div>
        )}

        <div className={items} key={value} ref={tabsWrapperRef} {...props}>
          {tabItems}
        </div>

        {suffixContent}
      </div>
    );
  }, [suffixContent, prefixContent]);

  useEffect(() => {
    setIndicatorWidths(
      // @ts-ignore
      Array.from(tabsWrapperRef?.current?.childNodes).map(
        // @ts-ignore
        ({ clientWidth }) => clientWidth,
      ),
    );
  }, [tabsWrapperRef.current, prefixContentRef?.current]);

  if (childrenCount < 1) {
    throw new Error("Tabs must have at least one Tab child");
  }

  return (
    <div className={wrapper}>
      {tabItemContent}
      {!skewed && (
        <div
          className={track}
          style={{
            minWidth: maxTrackWidth,
          }}
        >
          <div
            className={indicator}
            style={{
              width: indicatorWidths[value],
              transition: "ease .3s all",
              transform: `translateX(${indicatorPosition}px)`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TabList;
