import { db } from "../";
import { upsertCampaignContentDB } from "../handlers/campaignContent";
import {
  createCampaignDB,
  deleteCampaignDB,
  deleteCampaignsInBatchByIdsDB,
  updateCampaignDB,
} from "../handlers/campaigns";
import {
  createContractDB,
  deleteContractsByCampaignIdDB,
  updateContractDB,
} from "../handlers/contracts";
import {
  createSocialNetworkDB,
  upsertSocialNetworkDB,
} from "../handlers/socialNetworks";
import { deleteTiersByCampaignIdDB, upsertTierDB } from "../handlers/tiers";
import { transactionOrDatabase } from "../handlers/utils/transactions";
import type {
  Campaign,
  CampaignWithRelations,
  NewCampaignWithRelations,
} from "../schemas/campaign/campaign";
import type {
  Contract,
  NewContract,
} from "../schemas/campaign/campaignContract";
import { SocialNetworkEntity } from "../schemas/socialNetworks";
import { createSlug } from "../utils";

const createOrUpdateContracts = async (
  contracts: (Contract | NewContract)[],
  campaign: Campaign,
  isUpdate = false,
  trx?: any,
) => {
  if (isUpdate) {
    return await Promise.all(
      contracts.map((contract) => {
        const { network, ...rest } = contract as Contract;

        const payload = {
          ...rest,
          campaignId: campaign.id,
          projectId: campaign.projectId,
        } as Contract;

        if (rest?.id) {
          return updateContractDB(payload, trx);
        }

        return createContractDB(payload, trx);
      }),
    );
  }

  return await Promise.all(
    contracts.map((contract) => {
      const payload = {
        ...contract,
        campaignId: campaign.id,
        projectId: campaign.projectId,
      };
      return createContractDB(payload, trx);
    }),
  );
};

export const createCampaignLogicDB = async (
  payload: NewCampaignWithRelations,
  transaction?: any,
) => {
  return await transactionOrDatabase(transaction).transaction(
    async (trx: any) => {
      try {
        const { contracts, tiers, contents, socialNetwork, ..._campaign } =
          payload;
        const slug = createSlug(_campaign?.name!);
        const campaign = { ..._campaign, slug };

        const newCampaign = (await createCampaignDB(campaign, trx)) as Campaign;
        const newContracts = contracts?.length
          ? await createOrUpdateContracts(contracts, newCampaign, false, trx)
          : [];
        const newTiers = tiers?.length
          ? await Promise.all(
              tiers.map((tier) =>
                upsertTierDB({ ...tier, campaignId: newCampaign.id }, trx),
              ),
            )
          : [];
        const newContents = contents?.length
          ? await Promise.all(
              contents.map((content) =>
                upsertCampaignContentDB(
                  { ...content, campaignId: newCampaign.id },
                  trx,
                ),
              ),
            )
          : [];
        const newSocialNetwork = socialNetwork
          ? await createSocialNetworkDB(
              {
                ...socialNetwork,
                entityId: newCampaign.id,
                entityType: SocialNetworkEntity.Campaign,
              },
              trx,
            )
          : undefined;

        return {
          ...newCampaign,
          contracts: newContracts,
          tiers: newTiers,
          contents: newContents,
          socialNetwork: newSocialNetwork,
        };
      } catch (error) {
        console.error(error);
        trx.rollback();
      }
    },
  );
};

export const updateCampaignLogicDB = async (payload: CampaignWithRelations) => {
  return await db.transaction(async (trx) => {
    try {
      const { contracts, tiers, contents, socialNetwork, ..._campaign } =
        payload;

      const slug = createSlug(_campaign?.name!);
      const campaign = { ..._campaign, slug };

      const updatedCampaign = (await updateCampaignDB(
        campaign.id,
        campaign,
        trx,
      )) as Campaign;

      const newContracts = contracts?.length
        ? await createOrUpdateContracts(contracts, updatedCampaign, true, trx)
        : [];

      const newTiers = tiers?.length
        ? await Promise.all(
            tiers.map((tier) =>
              upsertTierDB({ ...tier, campaignId: updatedCampaign.id }, trx),
            ),
          )
        : [];

      const newContents = contents?.length
        ? await Promise.all(
            contents.map((content) =>
              upsertCampaignContentDB(
                { ...content, campaignId: payload.id },
                trx,
              ),
            ),
          )
        : [];

      const newSocialNetwork = socialNetwork
        ? await upsertSocialNetworkDB(
            {
              ...socialNetwork,
              entityId: updatedCampaign.id,
              entityType: SocialNetworkEntity.Campaign,
            },
            trx,
          )
        : undefined;

      return {
        ...updatedCampaign,
        contracts: newContracts,
        tiers: newTiers,
        contents: newContents,
        socialNetwork: newSocialNetwork,
      };
    } catch (error) {
      console.error(error);
      trx.rollback();
    }
  });
};

export const deleteCampaignLogicDB = async (
  id: string,
  creator: string,
): Promise<void> => {
  return await db.transaction(async (trx: any) => {
    await deleteCampaignDB(id, creator, trx);
  });
};

const deleteCampaignsInBatchByIdsWithTransaction = async (
  payload: string[],
  transaction?: any,
): Promise<void> => {
  await Promise.all([
    deleteCampaignsInBatchByIdsDB(payload, transaction),
    ...payload.map((campaignId) =>
      deleteContractsByCampaignIdDB(campaignId, transaction),
    ),
    ...payload.map((campaignId) =>
      deleteTiersByCampaignIdDB(campaignId, transaction),
    ),
  ]);
};

export const deleteCampaignsInBatchByIdsLogicDB = async (
  payload: string[],
  transaction?: any,
): Promise<void> => {
  if (transaction) {
    await deleteCampaignsInBatchByIdsWithTransaction(payload, transaction);
  }

  return await db.transaction(async (trx: any) => {
    await deleteCampaignsInBatchByIdsWithTransaction(payload, trx);
  });
};
