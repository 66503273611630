import type { FC, PropsWithChildren } from "react";
import React from "react";
import { useMemo } from "react";
import { tv } from "tailwind-variants";

import type { LabelProps } from "./type";

const labelClassName = tv({
  base: ["flex", "justify-center", "items-center", "w-fit", "h-fit"],
  variants: {
    border: {
      border: "border",
      "border-2": "border-2",
      "border-4": "border-4",
      "border-8": "border-8",
      "border-0": "border-0",
    },
    borderColor: {
      _cold: "border-_cold",
      _green: "border-_green",
      _hot: "border-_hot",
      accent: "border-accent",
      background: "border-background",
      danger: "border-danger",
      dark: "border-dark",
      disabled: "border-disabled",
      divider: "border-divider",
      info: "border-info",
      light: "border-light",
      link: "border-link",
      neutral: "border-neutral",
      overlay: "border-overlay",
      placeholder: "border-placeholder",
      primary: "border-primary",
      secondary: "border-secondary",
      status_completed: "border-status_completed",
      status_live: "border-status_live",
      status_upcoming: "border-status_upcoming",
      status_vote: "border-status_vote",
      success: "border-success",
      text: "border-text",
      transparent: "border-transparent",
      typography: "border-typography",
      warning: "border-warning",
    },
    color: {
      _cold: "text-_cold",
      _green: "text-_green",
      _hot: "text-_hot",
      accent: "text-accent",
      background: "text-background",
      danger: "text-danger",
      dark: "text-dark",
      disabled: "text-disabled",
      divider: "text-divider",
      info: "text-info",
      inherit: "text-inherit",
      light: "text-light",
      link: "text-link",
      neutral: "text-neutral",
      overlay: "text-overlay",
      placeholder: "text-placeholder",
      primary: "text-primary",
      secondary: "text-secondary",
      status_completed: "text-primary",
      status_live: "text-light",
      status_upcoming: "text-light",
      status_vote: "text-status_vote",
      success: "text-success",
      text: "border-text",
      transparent: "text-secondary",
      typography: "text-typography",
      warning: "text-warning",
    },
    size: {
      small: "text-xs md:text-sm",
      medium: "text-sm md:text-base",
      large: "text-base md:text-lg",
      "x-large": "text-lg md:text-3xl",
    },
    spacing: {
      small: "p-2",
      medium: "p-4",
      large: "p-6",
    },
    transform: {
      "normal-case": "normal-case",
      capitalize: "capitalize",
      lowercase: "lowercase",
      uppercase: "uppercase",
    },
    weight: {
      thin: "font-thin",
      extralight: "font-extralight",
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
      extrabold: "font-extrabold",
      black: "blackfont-black",
    },
  },
});

const LabelSquared: FC<PropsWithChildren<LabelProps>> = ({
  children,
  className = "",
  size = "small",
  color = "primary",
  spacing = "small",
  transform = "uppercase",
  weight,
  border,
  borderColor,
  ...props
}) => {
  const labelClassNames = useMemo(
    () =>
      labelClassName({
        color,
        size,
        spacing,
        transform,
        weight,
        border,
        // @ts-expect-error
        borderColor,
        className: `bg-${color} ${className}`,
      }),
    [className, size, color, spacing, weight, transform, border, borderColor],
  );

  return (
    <label className={labelClassNames} {...props}>
      {children}
    </label>
  );
};

export default LabelSquared;
