import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { jsonb, numeric, pgTable, text } from "drizzle-orm/pg-core";

import defaultSchema from "./base";
import { networkTokenTable } from "./networkToken";

export const networkTable = pgTable("network", {
  ...defaultSchema,
  key: text("key").default(""),
  endpoint: text("endpoint").default(""),
  name: text("name").default("").notNull(),
  chainId: numeric("chain_id"),
  // @deprecated use networkTokenTable instead
  coinTokenSymbols: jsonb("deprecated_coin_token_symbols")
    .$type<string[]>()
    .default([])
    .notNull(),
  rpcURLs: jsonb("rpc_urls").$type<string[]>().default([]).notNull(),
});

export const networkTableRelations = relations(networkTable, ({ many }) => ({
  tokens: many(networkTokenTable),
}));

type NetworkRelations = Partial<{
  tokens: InferSelectModel<typeof networkTokenTable>[];
}>;
export type Network = InferSelectModel<typeof networkTable>;
export type NetworkWithRelations = Network & NetworkRelations;
export type NewNetwork = InferInsertModel<typeof networkTable>;
