import type {
  Campaign as DbCampaign,
  CampaignType as DbCampaignType,
  Contract,
  NewCampaign,
  NewContract,
  NewTier,
  Tier,
} from "@whitelabel-engine/db";

import type { Address } from "../contract";

export enum CampaignEnum {
  TIER = "tier",
  PUBLIC_TIER = "public-tier",
  CLAIM = "claim",
  BURN = "burn",
}

export type Campaign = DbCampaign;

export type CampaignType = DbCampaignType;

export type CampaignPublicTier = Omit<Campaign, "type"> & {
  type: CampaignEnum.PUBLIC_TIER;
};

export type CampaignClaim = Omit<
  Campaign,
  "additionalInformation" | "requirementTable" | "infoTable" | "tiers" | "type"
> & {
  type: CampaignEnum.CLAIM;
};

export type CampaignSwap = Omit<
  Campaign,
  "additionalInformation" | "requirementTable" | "infoTable" | "tiers" | "type"
> & {
  type: CampaignEnum.BURN;
  targetPair: string;
};

export type CampaignWithContracts = Campaign & {
  contracts: Contract[];
  tiers: Tier[];
};

export enum CAMPAIGN_STATUS {
  PREVIOUS = "Previous",
  UPCOMING = "Upcoming",
  CURRENT = "Current",
}

// export type CampaignStatus = "Previous" | "Upcoming" | "Current";

export type CampaignWithContractsAndStatus = CampaignWithContracts & {
  status: CAMPAIGN_STATUS;
};

export type CampaignClaimWithContracts = CampaignClaim & {
  contracts: Contract[];
};

export type CampaignSwapWithContracts = CampaignSwap & {
  contracts: Contract[];
};

export type NewCampaignPublicTier = Omit<CampaignPublicTier, "id"> & {
  tiers: Tier[];
};

export type NewCampaignClaim = Omit<CampaignClaim, "id">;

export type NewCampaignSwap = Omit<CampaignSwap, "id">;

export type NewCampaignWithContracts = NewCampaign & {
  contracts: NewContract[];
  tiers: NewTier[];
};

export type NewCampaignClaimWithContracts = NewCampaignClaim & {
  contracts: NewContract[];
};

export type NewCampaignSwapWithContracts = NewCampaignSwap & {
  contracts: NewContract[];
};

export type NewCampaignPublicTierWithContracts = NewCampaignPublicTier & {
  contracts: NewContract[];
};

export type {
  CampaignContent,
  CampaignWithRelations,
  NewCampaignContent,
  NewCampaignWithRelations,
} from "@whitelabel-engine/db";
export { CampaignContentSection } from "@whitelabel-engine/db";

export type PaymentToken = {
  address: Address;
  price: bigint;
  priceFormatted: string;
  symbol: string;
  image?: string;
  disabled?: boolean;
};

export type CampaignTierStatus = "live" | "upcoming" | "completed" | "on_vote";
export enum CampaignTierStatusEnum {
  live = "live",
  upcoming = "upcoming",
  completed = "completed",
  on_vote = "on_vote",
}
