import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import {
  boolean,
  index,
  jsonb,
  pgEnum,
  pgTable,
  text,
  unique,
  uuid,
} from "drizzle-orm/pg-core";

import timestamp from "../../types/timestamp";
import defaultSchema from "../base";
import { projectsTable } from "../project/project";
import type { SocialNetworkEntity } from "../socialNetworks";
import { socialNetworkTable } from "../socialNetworks";
import type { NewTier, Tier } from "../tiers";
import { tiersTable } from "../tiers";
import { usersTable } from "../users";
import { campaignContentTable } from "./campaignContent";
import type { Contract, NewContract } from "./campaignContract";
import { campaignContractTable } from "./campaignContract";

export enum CampaignEnum {
  TIER = "tier",
  PUBLIC_TIER = "public-tier",
  CLAIM = "claim",
  BURN = "burn",
}

export const campaignTypeEnum = pgEnum("type", [
  CampaignEnum.TIER,
  CampaignEnum.PUBLIC_TIER,
  CampaignEnum.CLAIM,
  CampaignEnum.BURN,
]);
export type CampaignType = (typeof campaignTypeEnum.enumValues)[number];

export const campaignsTable = pgTable(
  "campaign",
  {
    ...defaultSchema,
    additionalInformation: text("additional_information").default("").notNull(),
    backgroundBanner: text("background_banner").default("").notNull(),
    banner: text("banner").default("").notNull(),
    categories: jsonb("categories").$type<string[]>().default([]),
    description: text("description").default("").notNull(),
    externalSaleLink: text("external_sale_link").default("").notNull(),
    isActive: boolean("is_active").default(true).notNull(),
    isEnabled: boolean("is_enabled").default(true).notNull(),
    logo: text("logo").default("").notNull(),
    name: text("name").default("").notNull(),
    priceToBePegged: boolean("price_to_be_pegged").default(false).notNull(),
    projectId: uuid("project_id")
      .notNull()
      .references(() => projectsTable.id, { onDelete: "cascade" }),
    shortDescription: text("short_description").default("").notNull(),
    slug: text("slug").default("").notNull(),
    snapshotDate: timestamp("snapshot_date"),
    targetPair: text("target_pair").default("").notNull(),
    type: campaignTypeEnum("type").notNull(),
    userId: uuid("user_id")
      .notNull()
      .references(() => usersTable.id, { onDelete: "cascade" }),
    voteSnapshotEndDate: timestamp("vote_snapshot_end_date"),
    voteSnapshotProposalId: text("vote_snapshot_proposal_id"),
  },
  (table) => ({
    idIdx: index("campaignId_Idx").on(table.id),
    projectIdIdx: index("campaignProjectId_Idx").on(table.projectId),
    userIdIdx: index("campaignUserId_Idx").on(table.userId),
    slugProjectIdUnique: unique("slug_project_id_unique").on(
      table.slug,
      table.projectId,
    ),
  }),
);

export const campaignRelations = relations(campaignsTable, ({ many, one }) => ({
  tiers: many(tiersTable),
  contracts: many(campaignContractTable),
  project: one(projectsTable, {
    fields: [campaignsTable.projectId],
    references: [projectsTable.id],
  }),
  creator: one(usersTable, {
    fields: [campaignsTable.userId],
    references: [usersTable.id],
  }),
  contents: many(campaignContentTable),
  socialNetwork: one(socialNetworkTable, {
    fields: [campaignsTable.id],
    references: [socialNetworkTable.entityId],
  }),
}));

export type CampaignRelationsSelect = Partial<{
  tiers: InferSelectModel<typeof tiersTable>[];
  contracts: Contract[];
  project: InferSelectModel<typeof projectsTable>;
  creator: InferSelectModel<typeof usersTable>;
  contents: InferSelectModel<typeof campaignContentTable>[];
  socialNetwork: Omit<typeof socialNetworkTable.$inferSelect, "entityType"> & {
    entityType: SocialNetworkEntity.Campaign;
  };
}>;
export type CampaignRelationsInsert = Partial<{
  tiers: InferInsertModel<typeof tiersTable>[];
  contracts: InferInsertModel<typeof campaignContractTable>[];
  contents: InferInsertModel<typeof campaignContentTable>[];
  socialNetwork: Omit<typeof socialNetworkTable.$inferInsert, "entityType"> & {
    entityType: SocialNetworkEntity.Campaign;
  };
}>;
export type Campaign = InferSelectModel<typeof campaignsTable>;
export type NewCampaign = InferInsertModel<typeof campaignsTable>;

export type CampaignWithRelations = Campaign & CampaignRelationsSelect;
export type NewCampaignWithRelations = NewCampaign & CampaignRelationsInsert;

export type CampaignPublicTier = Omit<Campaign, "type"> & {
  type: CampaignEnum.PUBLIC_TIER;
};

export type CampaignClaim = Omit<
  Campaign,
  "additionalInformation" | "requirementTable" | "infoTable" | "tiers" | "type"
> & {
  type: CampaignEnum.CLAIM;
};

export type CampaignSwap = Omit<
  Campaign,
  "additionalInformation" | "requirementTable" | "infoTable" | "tiers" | "type"
> & {
  type: CampaignEnum.BURN;
};

export type CampaignWithContracts = Campaign & {
  contracts: Contract[];
  tiers: Tier[];
};

export type CampaignClaimWithContracts = CampaignClaim & {
  contracts: Contract[];
};

export type CampaignSwapWithContracts = CampaignSwap & {
  contracts: Contract[];
};

export type NewCampaignPublicTier = Omit<CampaignPublicTier, "id"> & {
  tiers: Tier[];
};

export type NewCampaignClaim = Omit<CampaignClaim, "id">;

export type NewCampaignSwap = Omit<CampaignSwap, "id">;

export type NewCampaignWithContracts = NewCampaign & {
  contracts: NewContract[];
  tiers: NewTier[];
};

export type NewCampaignClaimWithContracts = NewCampaignClaim & {
  contracts: NewContract[];
};

export type NewCampaignSwapWithContracts = NewCampaignSwap & {
  contracts: NewContract[];
};

export type NewCampaignPublicTierWithContracts = NewCampaignPublicTier & {
  contracts: NewContract[];
};
