import type { InferInsertModel, InferSelectModel } from "drizzle-orm";
import { relations } from "drizzle-orm";
import { index, jsonb, pgTable, text, unique, uuid } from "drizzle-orm/pg-core";

import defaultSchema from "../base";
import type { ABIItem, MerkleProof } from "../campaign";
import { networkTable } from "../network";
import { projectsTable } from "./project";

export const projectRewardTable = pgTable(
  "project_reward",
  {
    ...defaultSchema,
    projectId: uuid("project_id")
      .notNull()
      .references(() => projectsTable.id, { onDelete: "cascade" }),
    address: text("address").$type<`0x${string}`>().notNull(),
    abi: jsonb("abi").$type<ABIItem[]>().default([]).notNull(),
    merkleProof: jsonb("merkle_proof")
      .$type<MerkleProof>()
      .default({ proofs: {}, root: "" })
      .notNull(),
    networkId: uuid("network_id")
      .notNull()
      .references(() => networkTable.id),
  },
  (table) => ({
    addressIdIdx: index("projectRewardAddress_Idx").on(table.address),
    networkIdIdx: index("projectRewardNetworkId_Idx").on(table.networkId),
    projectIdIdx: index("projectRewardProjectId_Idx").on(table.projectId),
    unq: unique("projectIdNetworkIdUnique").on(
      table.projectId,
      table.networkId,
    ),
  }),
);

export const projectRewardRelations = relations(
  projectRewardTable,
  ({ one }) => ({
    project: one(projectsTable, {
      fields: [projectRewardTable.projectId],
      references: [projectsTable.id],
    }),
    network: one(networkTable, {
      fields: [projectRewardTable.networkId],
      references: [networkTable.id],
    }),
  }),
);

export type ProjectRewardRelations = Partial<{
  project: InferSelectModel<typeof projectsTable>[];
  network: InferSelectModel<typeof networkTable>[];
}>;
export type ProjectRewardInsertRelations = {
  project?: typeof projectsTable.$inferInsert;
  network?: typeof networkTable.$inferInsert;
};

type ProjectRewardTable = typeof projectRewardTable;
export type ProjectReward = InferSelectModel<ProjectRewardTable>;
export type NewProjectReward = InferInsertModel<ProjectRewardTable>;
export type ProjectRewardWithRelations = ProjectReward & ProjectRewardRelations;
export type NewProjectRewardWithRelations = NewProjectReward &
  ProjectRewardInsertRelations;
