import { and, eq, isNull, sql } from "drizzle-orm";

import type { Transaction } from "../";
import { db, projectsTable } from "../";
import type {
  Configuration,
  NewConfiguration,
} from "../schemas/configurations";
import { configurationsTable } from "../schemas/configurations";
import parseRelations from "./utils/parseRelations";
import { transactionOrDatabaseWrapper } from "./utils/transactions";

const DEFAULT_SELECT = {
  id: configurationsTable.id,
  createdAt: configurationsTable.createdAt,
  updatedAt: configurationsTable.updatedAt,
  project: configurationsTable.projectId,
  deletedAt: configurationsTable.deletedAt,
  creator: configurationsTable.userId,
  favicon: configurationsTable.favicon,
  features: configurationsTable.features,
  i18n: configurationsTable.i18n,
  resources: configurationsTable.resources,
  theme: configurationsTable.theme,
  wallets: configurationsTable.wallets,
};

export async function getConfigurationsDB(
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.configurationsTable.findMany({
      where: isNull(configurationsTable.deletedAt),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(configurationsTable)
    .where(isNull(configurationsTable.deletedAt));
}

export async function getConfigurationByIdDB(
  id: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.configurationsTable.findFirst({
      where: and(
        eq(configurationsTable.id, id),
        isNull(configurationsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select()
    .from(configurationsTable)
    .where(
      and(
        eq(configurationsTable.id, id),
        isNull(configurationsTable.deletedAt),
      ),
    );

  return result[0];
}

export async function getConfigurationsByCreatorDB(
  creator: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.configurationsTable.findMany({
      where: and(
        eq(configurationsTable.userId, creator),
        isNull(configurationsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select()
    .from(configurationsTable)
    .where(
      and(
        eq(configurationsTable.userId, creator),
        isNull(configurationsTable.deletedAt),
      ),
    );
}

export async function getConfigurationsByProjectIdDB(
  project: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.configurationsTable.findFirst({
      where: and(
        eq(configurationsTable.projectId, project),
        isNull(configurationsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  const result = await db
    .select(DEFAULT_SELECT)
    .from(configurationsTable)
    .where(
      and(
        eq(configurationsTable.projectId, project),
        isNull(configurationsTable.deletedAt),
      ),
    );
  return result[0];
}

export async function getConfigurationsByProjectSlugDB(slug: string) {
  const { id: projectId } = (
    await db
      .select({ id: projectsTable.id })
      .from(projectsTable)
      .where(eq(projectsTable.slug, slug))
  )?.[0];

  const result = await db
    .select()
    .from(configurationsTable)
    .where(
      and(
        eq(configurationsTable.projectId, projectId),
        isNull(configurationsTable.deletedAt),
      ),
    );
  return result[0];
}

export async function getConfigurationFeaturesByProjectDB(
  projectId: string,
  withModels?: Record<string, boolean>,
) {
  if (withModels) {
    return await db.query.configurationsTable.findMany({
      where: and(
        eq(configurationsTable.projectId, projectId),
        isNull(configurationsTable.deletedAt),
      ),
      with: parseRelations(withModels),
    });
  }

  return await db
    .select({
      id: configurationsTable.id,
      project: configurationsTable.projectId,
    })
    .from(configurationsTable)
    .where(
      and(
        eq(configurationsTable.projectId, projectId),
        isNull(configurationsTable.deletedAt),
      ),
    );
}

export async function getConfigurationEdgeByProjectSlugDB(slug: string) {
  const { id: projectId } = (
    await db
      .select({ id: projectsTable.id })
      .from(projectsTable)
      .where(eq(projectsTable.slug, slug))
  )?.[0];

  return (
    await db
      .select({
        edge: configurationsTable.edge,
      })
      .from(configurationsTable)
      .where(
        and(
          eq(configurationsTable.projectId, projectId),
          isNull(configurationsTable.deletedAt),
        ),
      )
  )[0].edge;
}

export async function createConfigurationDB(
  configuration: NewConfiguration,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .insert(configurationsTable)
      .values(configuration)
      .returning();
  }, transaction);
}

export async function updateConfigurationDB(
  configuration: Configuration,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const result = await trxOrDb
      .insert(configurationsTable)
      .values({
        ...configuration,
        createdAt: new Date().toISOString(),
        updatedAt: null,
        deletedAt: null,
      })
      .onConflictDoUpdate({
        target: configurationsTable.id,
        set: {
          ...configuration,
          updatedAt: new Date().toISOString(),
        },
        where: eq(configurationsTable.id, configuration.id),
      })
      .returning();
    return result[0];
  }, transaction);
}

export async function deleteConfigurationDB(
  id: string,
  creator: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    const configuration = await getConfigurationByIdDB(id);
    if (configuration === undefined) {
      throw new Error(`Configuration with id ${id} not found`);
    }

    return await trxOrDb
      .update(configurationsTable)
      .set({
        deletedAt: new Date().toISOString(),
      })
      .where(
        and(
          eq(configurationsTable.id, id),
          eq(configurationsTable.userId, creator),
        ),
      )
      .returning();
  }, transaction);
}

export async function deleteConfigurationByProjectIdDB(
  projectId: string,
  creatorId: string,
  transaction?: Transaction,
) {
  return await transactionOrDatabaseWrapper(async (trxOrDb) => {
    return await trxOrDb
      .update(configurationsTable)
      .set({
        deletedAt: new Date().toISOString(),
        updatedAt: sql`now()`,
      })
      .where(
        and(
          eq(configurationsTable.projectId, projectId),
          eq(configurationsTable.userId, creatorId),
        ),
      )
      .returning();
  }, transaction);
}
