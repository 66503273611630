import type { ButtonProps } from "@whitelabel-engine/ui/Button";
import dynamic from "next/dynamic";
import type { ForwardedRef } from "react";
import { forwardRef, useMemo } from "react";

import { useDynamicConfigProvider } from "../DynamicConfigProvider";

const fetchLazyLoadedComponent = (name?: string) => {
  return dynamic(() => {
    switch (name) {
      case "@whitelabel-engine/ui/Button/ButtonIrregular":
        return import("@whitelabel-engine/ui/Button/ButtonIrregular");
      case "@whitelabel-engine/ui/Button/ButtonRectangleNoBorder":
        return import("@whitelabel-engine/ui/Button/ButtonRectangleNoBorder");
      case "@whitelabel-engine/ui/Button/ButtonFlatRoundCorners":
        return import("@whitelabel-engine/ui/Button/ButtonFlatRoundCorners");
      case "@whitelabel-engine/ui/Button/ButtonIrregularAnimated":
        return import("@whitelabel-engine/ui/Button/ButtonIrregularAnimated");
      default:
        return import("@whitelabel-engine/ui/Button");
    }
  });
};

const DynamicButton = forwardRef(function DynamicButton(
  props: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const features = useDynamicConfigProvider();
  const buttonModule = features?.["@whitelabel-engine/ui/Button"];

  const Component = useMemo(() => {
    return fetchLazyLoadedComponent(buttonModule as string);
  }, [features]);

  return (
    <Component {...props} ref={ref}>
      {props?.children}
    </Component>
  );
});

export default DynamicButton;
