import { t } from "@whitelabel-engine/i18n";
import {
  type CampaignTierStatus,
  CampaignTierStatusEnum,
} from "@whitelabel-engine/typings";
import Label from "@whitelabel-engine/ui/Label";
import type { LabelProps } from "@whitelabel-engine/ui/Label/type";
import type { FC } from "react";

const StatusLabel: FC<{
  status: CampaignTierStatus;
  size: LabelProps["size"];
  color?: LabelProps["color"];
}> = ({ status, size, color }) => {
  switch (status) {
    case CampaignTierStatusEnum.completed:
      return (
        <Label
          className="font-bold"
          size={size}
          color={color ?? `status_completed`}
        >{t`Completed`}</Label>
      );
    case CampaignTierStatusEnum.live:
      return (
        <Label
          className="font-bold"
          size={size}
          color={color ?? `status_live`}
        >{t`Live`}</Label>
      );
    case CampaignTierStatusEnum.upcoming:
      return (
        <Label
          className="font-bold"
          size={size}
          color={color ?? `status_upcoming`}
        >{t`Upcoming`}</Label>
      );
    case CampaignTierStatusEnum.on_vote:
      return (
        <Label
          className="font-bold"
          size={size}
          color={color ?? `status_vote`}
        >{t`On Vote`}</Label>
      );
    default:
      return null;
  }
};

export default StatusLabel;
